import React from 'react'
import Footer from '../../components/Footer/Footer'
import LeftPane from '../../components/LeftPane/LeftPane'
import RightPane from '../../components/RightPane/RightPane'
import bgVidio from '../../videos/background-video.mp4'
import layer1 from '../../Images/Layer-1.png'
import layer2 from '../../Images/Layer-2.png'
import image3 from '../../Images/gravity-img-3.png'
import image4 from '../../Images/gravity-img-4.png'
import logoImage2 from '../../Images/logo-img2.png'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './gravity.css'





function Gravity() {

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  
  return (
    <div className='home_container'>
      <div className='gravity_video-container'>
          <div className="gravity_logo-container">
            <img src={layer1} alt="animated-logo" /> 
            <img src={layer2} alt="animated-logo" /> 
          </div>
          <video autoPlay loop muted>
            <source src={bgVidio} type='video/mp4'/>
          </video>
      </div>

      <div className="gravity_services-container">
        <div className="gravity_services-left">
          <p data-aos="fade-right">UI/UX DESIGN</p>
          <p data-aos="fade-right">WEBSITE DEVELOPMENT</p>
          <p data-aos="fade-right">AR/VRSOLUTIONS</p>
          <p data-aos="fade-right">AI SOLUTIONS</p>
          <p data-aos="fade-right">DIGITAL AUDIT</p>
          <p data-aos="fade-right">SYSTEM AUTOMATION</p>
          <p data-aos="fade-right">AI MACHINE LEARNING</p>
          <p data-aos="fade-right">HOSTING SOLUTIONS</p>
          <p data-aos="fade-right">COMMUNICATION DESIGN</p>
          <p data-aos="fade-right">DIGITAL STRATEGY</p>
          <p data-aos="fade-right">INTEGRATED MARKETING</p>
          <p data-aos="fade-right">INTEGRATED COMMUNICATION</p>
          <p data-aos="fade-right">MOBILE APP DEVELOPMENT (ANDROID/IOS)</p>
        </div>
       <img src={logoImage2} alt="gravity-logo" />
        <div className="gravity_services-right">
          <p data-aos="fade-left">SOCIAL MEDIA MANAGEMENT</p>
          <p data-aos="fade-left">DIGITAL FIRST BRANDING</p>
          <p data-aos="fade-left">E-COMMERCE SERVICES</p>
          <p data-aos="fade-left">E-COMMERCE BRANDING</p>
          <p data-aos="fade-left">ONLINE MARKETING</p>
          <p data-aos="fade-left">CONTENT MARKETING</p>
          <p data-aos="fade-left">DIGITAL ACTIVATIONS</p>
          <p data-aos="fade-left">DIGITAL ADVERTISING</p>
          <p data-aos="fade-left">ONLINE EXPERIENCE</p>
          <p data-aos="fade-left">ONLINE EVENTS</p>
          <p data-aos="fade-left">LEAD GENERATION</p>
          <p data-aos="fade-left">DIGITAL BRANDING</p>
          <p data-aos="fade-left">ANIMATIONS</p>
        </div>
      </div>

      <div className="gravity_complete-work-container">
        <div className="gravity_complete-work-top">
          <p className='moving-text text1'>GRAVITY COLOMBO WILL DESIGN YOUR</p>
          <p className='moving-text text2'>OBJECTIVE. YOU CAN FIND ALL UNDER ONE CLICK</p>
          <p className='moving-text text3'>AS WE APPLY OUR DESIGNS TO YOUR</p>
          <p className='moving-text text4'>BRANDING, CONTENT, EXPERIENCES, INTERFACES,</p>
          <p className='moving-text text5'>AND EVEN INTERACTIONS.</p>
        </div>
        <div className="gravity_complete-work-bottom">
          <div className="text">
            <h2 data-aos="fade-up">COMPLETE YOUR WORK <br/> JUST IN FOUR STEPS</h2>

            <p data-aos="fade-up">GRAVITY COLOMBO WILL ADDRESS YOUR NEED MORE
            EFFECTIVELY.DO YOU WANT TO BUILD A BRAND, DRIVE
            ENGAGEMENT,ANDSALES,OR POSSIBLY GROW AFFINITY
            TOWARDS YOUR BRAND?</p>

            <p data-aos="fade-up">ALL THE SESPECIFIC NEEDS WILL BE IDENTIFIED BY
            GRAVITY COLOMBO SIMPLY IN 4 STEPS</p>

            <h3 data-aos="fade-up">WE <span className='cyan'>LISTEN TO YOUR NEEDS</span>, AND <span className='yellow'>WE LEARN ABOUT YOU</span>, <span className='pink'>YOUR AUDIENCE</span>, AND <span className='orange'>THE SETTING</span>.</h3>

            <p data-aos="fade-up">OUR TEAM WITH THE MOST TALENTED EXPERTS WILL THEN CREATE THE EXACT SOLUTIONS TO GRAB YOUR AUDIENCE TOWARDS YOUR BRAND. GRAVITY COLOMBO WILL NOT ONLY COUNT THE PEOPLE WE REACH BUT ALSO REACH THE PEOPLE THAT COUNT.</p>
          </div>
          <div className="image">
            <img src={image3} alt="gravity-colombo" />
          </div>
        </div>
      </div>

      <div className="gravity_impact-container">
        <div className="image">
          <img src={image4} alt="gravity-colombo" />
        </div>
        <div className="text">
          <h2 data-aos="fade-up">WHO WE IMPACT</h2>
          <p data-aos="fade-up" className='dark-b-text'>GRAVITY COLOMBO IS NOT THE TRADITIONAL DIGITAL MARKETING AGENCY YOU ARE THINKING OF. AT GRAVITY COLOMBO WE POWER THE CLIENTS WHO CHOOSE US AS THEIR SAVIOURS. WE BOOST THEIR STRENGTH BY MULTIPLYING THEIR ABILITY WITH GO-TO-MARKET STRATEGIES THAT AID WITH GROWING REVENUES, BUILDING BRANDS THAT GRAB THE PEOPLES' ATTENTION TO FALL IN LOVE AND REMEMBER THEM. THE SOLUTIONS FROM GRAVITY COLOMBO ENSURE OUR PARTNERS' SUCCESS IN COMPLETING THEIR NEEDS.</p>

          <p data-aos="fade-up" className='red-text'>WE PROMISE OUR PARNERS TO MAKE THEIR BRANDS LOOK GOOD, CLASSY, DIGITAL-FIRST, MOBILE-FRIENDLY, AND FUTURE-READY. IT'S NOT JUST A PROMISE BUT WORDS BUT ACTIONS AND CONSEQUENCES.</p>
        </div>
      </div>

      <div className="gravity_responsibility-container">
        <h2>SERVICES YOU GET FROM GRAVITY COLOMBO</h2>
        <p>GRAVITY COLOMBO TAKES THEIR FULLEST RESPONSIBILITY FOR ALL OUR WORK. ALL OUR WORK IS DONE WITH THE BEST QUALITY THAT YOU WILL FIND IN THE DIGITAL MARKET WORLD. THE MORE WE DO THE WORK PERFECTLY THE MORE WE FEEL BETTER. THIS EVEN MAKES OUR BRANDS ACHIEVE GREATER.</p>
      </div>

      <div className="pane_container">
        <LeftPane/>
        <RightPane/>
      </div>

      <Footer/>
    </div>
  )
}

export default Gravity