import React from 'react'
import './content.css'

function Content() {
  return (
    <div>

    </div>
  )
}

export default Content