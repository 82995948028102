import { useState, useEffect } from 'react'
import './cursor.css'


function Cursor() {
    const [cursorPosition, setCursorPosition] = useState ({X: 0, Y: 0});

    useEffect(()=>{
        const updateCursorPosition = (e) => {
            const {clientX, clientY} = e;
            setCursorPosition({X: clientX, Y: clientY});
        };

        window.addEventListener('mousemove', updateCursorPosition);
        return() => {
            window.removeEventListener('mousemove', updateCursorPosition);
        };
    },[]);


  return (
    <div className='cursor' style={
        {left: cursorPosition.X - 10, 
        top: cursorPosition.Y -10,}} >
    </div>
  )
}



export default Cursor