import React from 'react'
import './projects.css'

function Projects() {
  return (
    <div>
      
    </div>
  )
}

export default Projects