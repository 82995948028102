import React from 'react'
import video14 from '../../videos/Social-Media-Managemet.mp4'
import video15 from '../../videos/Digital-First-Branding.mp4'
import video16 from '../../videos/E-Commerce-Services.mp4'
import video17 from '../../videos/E-Commerce-Branding.mp4'
import video18 from '../../videos/Online-Marketing.mp4'
import video19 from '../../videos/Content-Marketing.mp4'
import video20 from '../../videos/Digital-Activations .mp4'
import video21 from '../../videos/Digital-Advertising.mp4'
import video22 from '../../videos/Online-Experience.mp4'
import video23 from '../../videos/Online-Events.mp4'
import video24 from '../../videos/Lead-Generation.mp4'
import video25 from '../../videos/Digital-Branding.mp4'
import video26 from '../../videos/Animations.mp4'
import './rightPane.css'


function RightPane() {

  return (
    <section className='rightpane_container'>
      <div className="box bg-yellow-purple">
        <div className="pane_text-wrapper" style={{height:"70%"}}>
          <h2>SOCIAL MEDIA MANAGEMENT</h2>
          <p>Social media platforms offer unparalleled opportunities for businesses to reach and engage with their target audience on a massive scale. Just takling to consumers through media is not a working thing in the present. At Gravity Colombo, it's not the method. We make the brands talk to humans. We create stories that make emotional bonds between your product and the audience. Gravity Colombo will craft your story of quality as they are always engaging. Because engaging stories do better always. They will touch the hearts and stay in their minds.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video14} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-white">
        <div className="pane_text-wrapper">
          <h2>DIGITAL FIRST BRANDING</h2>
          <p>This refers to an approach where businesses prioritize their online presence as the primary means of brand communication. Digital Firstbranding recognizes that online makes the best platform with vast opportunities to connect with audiences. At Gravity Colombo, we are consistent with the task we do. We make your brands look great.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video15} type='video/mp4'/>
          </video>
        </div>
      </div>
      
      <div className="box bg-white-yellow">
        <div className="pane_text-wrapper">
          <h2>E-COMMERCE SERVICES</h2>
          <p>Gravity Colombo's obsession to make things look great and perform better makes everything start. Our procedure begins from the base. As an e-commerce solution provider, we keep things simple and use space to let the customers know what is important while it will respect their time. This respect towards the customer will make them stay longer on the site.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video16} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-purple">
        <div className="pane_text-wrapper">
          <h2>E-COMMERCE BRANDING</h2>
          <p>It is believed that, once you catch the eye of a prospective buyer your offering does the rest of the work. Thus, good e-commerce branding is just about what would make someone stop mid-scroll and take another glance. Gravity Colombo solves all. We will make your brand look good and attractive on an e-commerce platform.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video17} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-white">
        <div className="pane_text-wrapper">
          <h2>ONLINE MARKETING</h2>
          <p>Online marketing or else Digital marketing, is a rapidly evolving field that utilizes technology to promote products, services, or brands through a number of digital channels. This type of online marketing has become a crucial strategy for businesses. This does not mean it's only for large scale but also for all sizes of businesses. This qualifies them to connect with consumers in a highly besieged and tailored manner.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video18} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-yellow">
        <div className="pane_text-wrapper">
          <h2>CONTENT MARKETING</h2>
          <p>Content marketing is simply making awareness. Gravity Colombo is here to people know that there is your brand to fulfill their specific needs.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video19} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-purple">
        <div className="pane_text-wrapper">
          <h2>DIGITAL ACTIVATION</h2>
          <p>DIGITAL Activation is the next bound of marketing which is more absorbed in involvements, contributions, and plunders rather than just on products and services. Gravity Colombo makes sure your products are best sellers commanding a significant market share</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video20} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-white">
        <div className="pane_text-wrapper" style={{height:"70%"}}>
          <h2>DIGITAL ADVERTISING</h2>
          <p>Social media platforms offer unparalleled opportunities for businesses to reach and engage with their target audience on a massive scale. Just talking to consumers through media is not a working thing in the present. At Gravity Colombo, it's not the method. We make the brands talk to humans. We create stories that make emotional bonds between your product and the audience. Gravity Colombo will craft your story of quality as they are always engaging. Because engaging stories do better always. They will touch the hearts and stay in their minds.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video21} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-yellow">
        <div className="pane_text-wrapper">
          <h2>ONLINE EXPERIENCE</h2>
          <p>Gravity Colombo put in action a plan that will optimize the use of the digital real estate for your brand. We will do this by even using the dead links to bring flourishing nature's harsh reality.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video22} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-purple">
        <div className="pane_text-wrapper">
          <h2>ONLINE EVENTS</h2>
          <p>The brand is not just a set of logos, websites, and social media content. Branding and communication are the core of a sound marketing strategy. That is why Gravity Colombo helps you to create and host grat online events according to your needs.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video23} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-white">
        <div className="pane_text-wrapper" style={{height:"70%"}}>
          <h2>LEAD GENERATION</h2>
          <p>Social media platforms offer unparalleled opportunities for businesses to reach and engage with their target audience on a massive scale. Just talking to consumers through media is not a working thing in the present. At Gravity Colombo, it's not the method. We make the brands talk to humans. We create stories that make emotional bonds between your product and the audience. Gravity Colombo will craft your story of quality as they are always engaging. Because engaging stories do better always. They will touch the hearts and stay in their minds.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video24} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-yellow">
        <div className="pane_text-wrapper">
          <h2>DIGITAL BRANDING</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video25} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-purple">
        <div className="pane_text-wrapper">
          <h2>ANIMATIONS</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video26} type='video/mp4'/>
          </video>
        </div>
      </div>
    </section>
  )
}

export default RightPane;
