import React from 'react'
import video1 from '../../videos/Ui-Ux-Design.mp4'
import video2 from '../../videos/Website-Development.mp4'
import video3 from '../../videos/Ar-Vr-Solutions.mp4'
import video4 from '../../videos/Ai-Solutions.mp4'
import video5 from '../../videos/Digital-Audit.mp4'
import video6 from '../../videos/System-Automation.mp4'
import video7 from '../../videos/Ai-Machine-Learning.mp4'
import video8 from '../../videos/Hosting-Solutions.mp4'
import video9 from '../../videos/communication-design.mp4'
import video10 from '../../videos/Digital-Strategy.mp4'
import video11 from '../../videos/Integrated-Marketing.mp4'
import video12 from '../../videos/Integrated-Communication.mp4'
import video13 from '../../videos/Mobile-App-Development.mp4'
import './leftPane.css'

function LeftPane() {
  return (
    <section className='leftpane_container'>
      <div className="box bg-purple-yellow">
        <div className="pane_text-wrapper">
          <h2>UI/UX DESIGN</h2>
          <p>UI/UX design, standing for User Interface and User Experience design, plays a critical role in creating successful digital experiences for users. Effective UI/UX design has become increasingly important as businesses strive to provide seamless and enjoyable experiences for their customers. This should be liberating instead of restrictive. Gravity Colombo builds a destination incorporating the ethos of the company.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video1} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-purple">
        <div className="pane_text-wrapper">
          <h2>WEBSITE DEVELOPMENT</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video2} type='video/mp4'/>
          </video>
        </div>
      </div>
      
      <div className="box bg-yellow-white">
        <div className="pane_text-wrapper">
          <h2>AR/VR SOLUTIONS</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video3} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-yellow">
        <div className="pane_text-wrapper">
          <h2>AI SOLUTIONS</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video4} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-purple">
        <div className="pane_text-wrapper">
          <h2>DIGITAL AUDIT</h2>
          <p>UI/UX design, standing for User Interface and User Experience design, plays a
          critical role in creating successful digital experiences for users. Effective UI/UX
          design has become increasingly important as businesses strive to provide seamless
          restrictive. Gravity Colombo builds a destination incorporating the ethos of the
          company.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video5} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-white">
        <div className="pane_text-wrapper">
          <h2>SYSTEM AUTOMATION</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video6} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-yellow">
        <div className="pane_text-wrapper">
          <h2>AI MACHINE LEARNING</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video7} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-purple">
        <div className="pane_text-wrapper">
          <h2>HOSTING SOLUTIONS</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video8} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-white">
        <div className="pane_text-wrapper">
          <h2>COMMUNICATION DESIGN</h2>
          <p>When a communication strategy is designed for a brand it should be significant. Designs should always be influenced by culture as it's a powerful thing at all times. Gravity Colombo understands the finer naunces of the brand. Which will enhance the path to reach the target audience.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video9} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box  bg-purple-yellow">
        <div className="pane_text-wrapper">
          <h2>DIGITAL STRATEGY</h2>
          <p>UI/UX design,standing for User Interface and User Experience design, plays a critical role in creating successful digital experiences for users Effective UI/UX design has become increasingly important as businesses strive to provide seamless and enjoyable experiences for their customers.This should be liberating instead of restrictive. Gravity Colombo builds a destination incorporating the ethos of the compan</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video10} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-white-purple">
        <div className="pane_text-wrapper">
          <h2>INTEGRATED MARKETING</h2>
          <p>Integrated marketing recognizes that consumers engage with brands through multiple touch points both online and offline. At Gravity, we understand our role will aid you to achieve these.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video11} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-yellow-white">
        <div className="pane_text-wrapper">
          <h2>INTEGRATED COMMUNICATION</h2>
          <p>To successful implement integrated communication towards your brand, Gravity Colombo will establish a well-defined communication strategy for your brand. Strating with strategic planning content creation and optimization.</p>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video12} type='video/mp4'/>
          </video>
        </div>
      </div>

      <div className="box bg-purple-yellow">
        <div className="pane_text-wrapper">
          <h2>MOBILE APP DEVELOPMENT (ANDROID/IOS)</h2>
        </div>
        <div className="gravity_video-element">
          <video autoPlay loop muted>
            <source src={video13} type='video/mp4'/>
          </video>
        </div>
      </div>


    </section>
  )
}

export default LeftPane;
