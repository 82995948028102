import { BrowserRouter,Routes, Route } from "react-router-dom";
import NavBar from "./components/NavigationBar/NavBar";
import Gravity from "./Pages/Gravity/Gravity";
import Projects from "./Pages/Projects/Projects";
import Content from "./Pages/Content/Content";
import Offering from "./Pages/Offering/Offering";
import Contact from "./Pages/Contact/Contact";
import Cursor from "./components/Cursor/Cursor";
import './index.css';
import { Style } from "@mui/icons-material";


function App() {
  return (
    <BrowserRouter>
      <NavBar/>
      <Routes>
        <Route index element={<Gravity/>}/>
        <Route path='projects' element={<Projects/>}/>
        <Route path='content' element={<Content/>}/>
        <Route path='offering' element={<Offering/>}/>
        <Route path='contact' element={<Contact/>}/>
      </Routes>
      <Cursor className={Style.cursor}/>
    </BrowserRouter>
  );
}

export default App;
