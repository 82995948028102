import Footer_img from '../../Images/footer-img.png'
import CopyrightIcon from '@mui/icons-material/Copyright';
import './footer.css'

function Footer() {


  return (
    <footer>
      <div className="footer_contact-us-div">

        <div className="footer_top">
          <h1>Contact Us</h1>
        </div>
        
        <div className="footer_bottom">
          <div className="footer_left">
            <div className="footer_wrapper-link">
              <a href="/" className="footer_link">For a project</a>
            </div>
            <div className="footer_wrapper-link">
              <a href="/" className="footer_link">For work & practices</a>
            </div>
            <div className="footer_wrapper-link">
              <a href="/" className="footer_link">To offer professional services</a>
            </div>
          </div>
          
          <div className="footer_right">
            <h2>Sign up for our newsletter to stay up to date!</h2>
            <div className="footer_form">
              <form action="post">
                <input name="EMAIL" placeholder='Write your email' className="footer_email" />
                <div className="privacy_policy">
                  <div className="footer_checkbox-custom">
                    <input id="privacy_policy" type="checkbox" name="privacy_policy" className="footer_checkbox" value="footer_checkbox"/>
                    <label for="privacy_policy"><span>I accept the <a href="/">privacy policy</a></span></label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_img-div">
        <img src={Footer_img} alt="footer-img" />
      </div>

      <div className="footer_contacts-div">
        <div className="footer_contacts">
          <div className="contacts-wrapper">
            <p className='contacts-info'>T. +94779150352 / +94778818464 <br/>general@gravitycolombo.com</p>
            <a href="/">How to get there</a>
          </div>
        </div>

        <div className="footer_social-media">
          <div className="social-left">
            <a href="http://www.linkedin.com/in/hireme-gravity" className="social-media">Linkedin</a>
            <a href="https://twitter.com/ColomboHireme" className="social-media">Twitter</a>
          </div>
          <div className="social-right">
            <a href="https://www.facebook.com/profile.php?id=100091662976556&mibextid=ZbWKwL" className="social-media">Facebook</a>
            <a href="https://instagram.com/gravity.colombo?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" className="social-media">instagram</a>
          </div>
        </div>

        <div className="footer_copyrights">
          <a href="/" className="terms-contion">Terms & Conditions</a>
          <p className='copyrights'>
            <CopyrightIcon className='copyright-icon' sx={{ fontSize: {xs: 12, sm: 20} }}/>
            GRAVITY COLOMBO - All rights reserved
          </p>
        </div>
      </div>
      
    </footer>
  )
}

export default Footer

